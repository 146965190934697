.forgot-password-section {
   
    /* Emerald 500 */
    display: flex;
    flex-direction :column;
    align-items: center;
    justify-content: center;
    background-color :'#fff';
    width: 370px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border-width: 2px solid gray;
    padding-bottom: 50px;

}

.form-container {
   
    width: 320px;
    /* Adjust as necessary */
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    padding: 16px;
}

.welcome-message {
    text-align: center;
    
    font-weight: 500;
    font-size: 24px;
    font-weight: bold;
    /* Adjust as necessary */
 
    color: #047857;
}

.success-message {
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 24px;
    /* Adjust as necessary */
}

.icon-container {
    background-color: rgb(30, 109, 170);
    color: #f1faf7;
    /* Emerald 500 */
    width: fit-content;
    margin: 0 auto;
    padding: 16px;
    border-radius: 50%;
}

.otp-label,
.phone-label {
    font-weight: bold;
    font-size: 20px;
    color: rgb(6, 98, 114);
    text-align: center;
}

.otp-input {
   
    height: 50px !important;
    background-color: #fff;
    font-weight: bold;
    border-radius: 10px;
    border: 1px solid #ccc;
    text-align: center;
}
 
.submit-button {
    margin-top: 10px;
    background-color: #047857;
    /* Emerald 600 */
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: white;
    border-radius: 8px;
    border: none;
    /* Remove default border */
    cursor: pointer;
}

.loading-spinner {
    margin-top: 2px;
    animation: spin 1s linear infinite;
    /* Add spin animation */
}

/* Keyframes for loading spinner animation */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}