.card-container:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

h3 {
    font-size: 0.9rem;
    font-family: "Content", sans-serif;
}

p {
    font-size: 0.9rem;
    color: #555;
}

button.ant-btn {
    font-family: "Content", sans-serif;
}

.ant-tag {
    font-size: 0.9rem;
    font-family: "Content", sans-serif;
}

.card-container{
    display: flex;
}