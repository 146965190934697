.Model-Setting-Grid {
    width: 100%;
    display: grid;
    gap: 16px;
    /* Adjust the spacing between grid items as needed */
}
.Header-Setting-Grid {
   display: flex;
   align-items: center;
   justify-content: space-between;
   justify-items: center;
   /* background-color: #a2d9ce; */
   width: 100%;
   gap: 10px;
     
}
.Header-Setting-Grid-R{
    display: flex;
        width: 100%;
         
}
@media screen and (max-width: 767px) {

    /* Mobile screens */
    .Model-Setting-Grid {
        grid-template-columns: 1fr;
        /* 1 column */
    }
                   .Header-Setting-Grid {
                    
                       align-items: center;
                       flex-direction: column;
                       gap: 10px;
        
                   }
                                .Header-Setting-Grid-R {
                                    align-items: center;
                                    flex-direction: column;
                                }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    /* Tablet screens */
    .Model-Setting-Grid {
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns */
    }
                         .Header-Setting-Grid {
                        
                             align-items: center;
                             flex-direction: column;
                             gap: 10px;
                            
                         }
                                                   .Header-Setting-Grid-R {
                            align-items: center;
                                                       flex-direction: column;
                                                   }
}

@media screen and (min-width: 1025px) {

    /* Laptop and larger screens */
.Model-Setting-Grid>div {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 16px;
        width: 100%;
    }

}