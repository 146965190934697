 
.menu-card {
      
    background-color:#f8f9f9;
    border-radius: 10px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
 
    gap: 10px;
}

.menu-item {
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    margin: 0 15px;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc;
    /* Replace with your desired color */
}

.icon-container {
    width: 30px;
    height: 30px;
    background-color: #f0f0f0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.text {
    flex: 1;
    font-size: 16px;
    color: #333;
}

.badge {
    background-color: #2e7d32;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50%;
    padding: 2px 8px;
    margin-left: auto;
}

.arrow-icon::before {
    content: '→';
    font-size: 16px;
    color: #999;
    margin-left: 10px;
}
 
         .custom-screen {
    
             display: flex;
             padding: 10px;
            justify-content: center;
             flex-direction: row;
             /* Align horizontally */
         }
                           .custom-screen button {
            border-radius: 15px;
                              margin: 5px;
                               /* Align horizontally */
                           }

            .custom-screen Button {
                background-color: #333;
                height: 40px;
                border-radius: 50px;
            }
 /* Media query for screen width 900px or below */
 @media only screen and (max-width: 900px) {
     .custom-screen {
        
        display: flex;
       padding: 10px;
      
         flex-direction: column;
         /* Align horizontally */
     }
         .custom-screen Button {
            
            height: 40px;
            width: 230px;
             margin-top: 5px;
             border-radius:20px;
         }

                  .model-setting {
                        
                      display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                  }
                                  
 }

 .model-setting{
    display: flex;
    
 }



.Leave-Setting-Grid {
    display: flex;
    width: 100%;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .Leave-Setting-Grid {

        flex-direction: column;
    }

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .Leave-Setting-Grid {
        width: 100%;
        flex-direction: column;
    }
}

@media screen and (min-width: 1025px) {
    .Leave-Setting-Grid {

        flex-direction: row;
    }
}