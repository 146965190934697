.container {
    height: "auto";
    width: 400px;
   
    /* background: linear-gradient(#f4f4f6, #f9fafb); */
    margin: auto;
    border-radius: 10px;
    padding: 25px;
    background: "white";
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
        0 6px 20px rgba(0, 0, 0, 0.1);
}
.container1{
    height: 500px;
    width: 400px;
    background: linear-gradient(#f8f7fb, #ebecee);
    margin: auto;
    border-radius: 10px;
    padding: 25px;
  
}
.textStyle{
    height: 40px;
    border-radius: 50px;
    font-weight: bold !important; /* Force bold style */;
    font-size: medium;
    margin: 10;
    font-family: 'Content', sans-serif !important;
     
}
 .textStyle.ant-input {
     font-weight: bold !important;
    font-family: 'Content', sans-serif !important;
 }
 .ant-input {
     font-weight: bold !important;
        font-family: 'Content', sans-serif !important;
 
 }
.bgLogin{
    background-image: url('https://png.pngtree.com/png-vector/20220526/ourmid/pngtree-online-registration-or-sign-up-login-for-account-on-smartphone-app-png-image_4740849.png');
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
    height: 300px;
  }
  .stybgLogin{
    justify-content: center;
     background-color: aqua;
    
  }
