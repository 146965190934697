body {
  
/*  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', 'Content';
   */
font-family: "Content", system-ui;

}
 /* Apply font to all Ant Design dropdowns */
 .ant-dropdown,
 .ant-select-dropdown {
   font-family: 'Content', sans-serif !important;
 }

 /* Apply font to dropdown menu items */
 .ant-dropdown-menu,
 .ant-select-item {
   font-family: 'Content', sans-serif !important;
 }



.ant-table-content{
    /* background-color: rgb(127, 127, 127); */
    color: rgb(58, 114, 154);
    font-family: 'Content', sans-serif;
    /* font-family: 'Content', serif; */
    font-size: 13px;
    
    
   };
 /* table */
.ant-table {
    font-size: 9px;
  }
  .ant-
  /* row data */
  .ant-table-tbody > tr > td {
    height: 50px;
    padding: 4px;
  }
        .ant-table-tbody>tr>td  >Tag {
          font-family: "Content", sans-serif;
        }
  /* row tags */
  .my-tag {
    font-size: 11px;
  }
  .ant-table-thead > tr > th {
    height: 5px;
    padding: 4px;
  }
  .h5{
    color: rgb(14, 14, 14);
    font-family: 'Content', sans-serif;
    /* font-family: 'Content', serif; */
    font-size: 20px;
  }
.model_style{
    font-family: 'Content' ;
    /* font-family: 'Content', serif; */
   
   
}
 
 
.custom-form-item{
  font-family: 'Content' ;
}
 

.styleTageStatus{
  font-size:'11px';
  height:'30px';
  border-radius:'30px';
  padding-top:'5px';
  padding-left:'10px';
  padding-right:'10px';
}

.sprinCustome{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(249, 249, 249, 0.7);
}
 
.sprinSave-container {
  position: relative;
  max-width: 400px; /* Adjust the value based on your design */
  margin: 0 auto;  /* Center the container horizontally */
}

.sprinSave {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(249, 249, 249, 0.7);
  width: 100%;
  height: 100%;
 z-index: 1; /* Ensure the spinner is above other content within the container */
}
.loading-overlay {
  position: fixed;
  top: 200%;
  left: 50%;
  transform: translate(-50%, -50%);
 
  justify-content: center;
  align-items: center;
  background: rgba(123, 24, 24, 0.7);
  /* Semi-transparent background */
  width: 100%;
  height: 100vh;
  z-index: 1;
  /* Ensure the overlay is above other content */
}


.custom-row-class{
 
  align-items: 'center' ;
  
  height: 53px;
  font-family: 'Content' ;
      
}

.styleTageStatus {
  font-size:  13px;
  height: 30px;
  border-radius:30px;
  padding-top:5px;
  padding-left:10px;
  padding-right:10px;
  font-family: 'Content' ;
}
.styleTageStatusForm .react-select__single-value {
  color: cadetblue;
}

.styleTageStatusForm {
  font:  14px;
  border-radius:5px;
  height: 37px;
  color: rgb(6, 102, 146) !important;
  font-family: 'Content'  !important;
}
 .styleDatePicker{
  color: rgb(6, 102, 146) !important;
  border: 01px solid #d9d9d9;
  border-radius: 5px;
  width: 250px;
  height: 37px;
 }

.profileStyle{
  font-family: 'Content' ;
 
}
.select {
  color: rgb(6, 102, 146) !important;
  height: 37px;
  font-family: 'Content'  !important;
}
.custom-select .ant-select-selection {
  color: rgb(6, 102, 146) !important;
  height: 37px;
  font-family: 'Content'  !important;
}
.ionavatar{
 position: relative;
  width: 150px;
	height: 150px;
	box-sizing: border-box;
	border: 5px white solid;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 5px 15px 0px rgba(153, 151, 151, 0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
	img { width: 100%; height: auto; }
  
 
}
.ionavatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the circle without distortion */
}
.ionavatar1{
 
  width: 30px;
	height: 30px;
	box-sizing: border-box;
	border: 1px white solid;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 2px 5px 0px rgba(153, 151, 151, 0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
	img { width: 100%; height: auto; }
 
}

.ionavatarLaout{
 
  width: 30px;
	height: 30px;
	box-sizing: border-box;
	border: 2px rgb(247, 245, 245) solid;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 2px 5px 0px rgba(153, 151, 151, 0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
	img { width: 100%; height: auto; }
 
}
.ionavatarLaout img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the circle without distortion */
}

.styleDivChoseFile {
   height: 40px;
   width: 250px;
   padding: 7px;
   background-color: rgb(147, 167, 184);
  border-radius:10px;
   
} 


.print-image {
  max-width: 100%;
  max-height: 100%;
  
  object-fit: contain;
}

.text-overlay {
  position: absolute;
  text-align: center;
  color: rgb(23, 6, 6);  /* Adjust text color as needed */
}

.text-on-image-name {
  font-size: 13px;  /* Adjust font size as needed */
  font-family: 'Content', serif;
  font-weight: bold;  /* Adjust font weight as needed */
  /* Add any other styles you want for the text */
}
 
.text-on-image-name_letters {
  font-size: 13px;  /* Adjust font size as needed */
  font-family: 'Noto Serif Khmer', serif;
  text-align: start;
  /* Add any other styles you want for the text */
}
.text-on-image-name_letters_mission {
  font-size: 14px;
  text-align: start;
  /* Adjust font size as needed */
  font-family: 'Content', serif;
 
 
  /* Add any other styles you want for the text */
}
.text-on-image-name_rightmanager {
  font-size: 15px;  /* Adjust font size as needed */
  font-family: 'Noto Serif Khmer', serif;
  text-align: start;
  width: 580px;
  white-space: pre-wrap;
  /* Add any other styles you want for the text */
}
.text-on-image-name_rightmanager1 {
  font-size: 15px;  /* Adjust font size as needed */
  font-family: 'Moul', serif;
  /* Add any other styles you want for the text */
}.text-on-image-name_rightmanager_mission {
  font-size: 14px;
  /* Adjust font size as needed */
  font-family: 'Moul', serif;
  /* Add any other styles you want for the text */
}
.text-on-image-name_lettersBold{
  font-size: 13px;  /* Adjust font size as needed */
  font-family: 'Noto Serif Khmer', serif;
  color: rgb(20, 94, 232);
  line-height: 1em;
}
 
.image-container-report {
  position: relative;
}

.text-overlaycard {
   display: flex;
   flex-direction: column;
  margin-top: 6.5cm;
  width: 280px;
  word-wrap: break-word;
  position: absolute;
  font-size: 12px;
  color: rgb(5, 57, 106);  /* Adjust text color as needed */
  font-family: 'Content', serif;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  justify-items: center;
  line-height: 1.7; 
}


.qrcode-overlaycard{
  margin-top: 7.5cm;
  margin-left: 4.5cm;
   
  position: absolute;
  color: rgb(18, 89, 156);  /* Adjust text color as needed */
  align-items: flex-start;
    
}


.qrcode-overlaycard-BackPapar{
  margin-top: 3cm;
  margin-left: 2cm;
   
  position: absolute;
  color: rgb(18, 89, 156);  /* Adjust text color as needed */
  align-items: flex-start;
 
}

.avatar-card-profile{
  position: absolute;
  margin-top: 3.9cm;
  margin-left: 2.2cm;
  width: 120px;
	height: 120px;
	box-sizing: border-box;
	border: 5px white solid;
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 5px 15px 0px rgba(153, 151, 151, 0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
	img { width: 100%; height: auto; }
}


/* Default styles */
.print-content {
  display: none;
  
}

.print-content-card {
  display: none;
}

/* Print styles for Scenario 1 */
@media print  {
  .print-content {
    display: block;
   
    
  }

  .print-container {
    display: none;
  }

  .print-container-card {
    display: none;
  }

  .print-content-card {
    display: flex;
    flex-wrap: wrap;
  }

  .front-paper {
    display: flex;
    flex-wrap: wrap;
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }

  .back-paper {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
  }

 
}

 .buttonstylekhmer{
  background-color:rgb(11, 145, 112);
  font-family: 'Content', sans-serif;
  font-size: 12px;
 }
 .khmer-font {
 
      font-family: 'Content', sans-serif;
      font-size: 12px;
    }
 .buttonstylekhmerRed{
  background-color:rgb(162, 14, 41);
 }

 .custom-file-label {
  position: absolute;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  top: 10;
  left: 5;
}

 
 
 #reader video {
   border-radius: 10px;
 }
 #reader button {
  background-color: #4fa94d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}



#reader input {
  /* Apply styles to the input element inside the #reader div */
  /* Example styles */
  background-color: #4fa94d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}


/* Define background color for even rows */
.even {
  background-color: #1079c9;
}

/* Define background color for odd rows */
.odd {
  background-color: #9bc219;
}

/* Define hover effect */
.custom-form-item tbody tr:hover {
  background-color: #c8e6c9;
}

.word-wrap {
  white-space: pre-wrap;
  font-family: 'Content', sans-serif;

}
.word-wrap_textinput {
 height: 80px;
  white-space: pre-wrap;
  font-family: 'Content', sans-serif;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
    padding: 10px;
    
  background-color: rgb(215, 229, 232);
}

 

.ant-table .ant-table-tbody > tr > td {
  padding: 5px;
}
.ant-table .ant-table-columns{
  width: 300px;
  color: #1079c9;
}
.ant-tabs .ant-tabs-tab {
  font-family: 'Content', sans-serif;
  padding:  2px;
  font-weight: bold;
  color:#2e86c1;
}
.ant-tabs {
  padding-top: -10px;
}
.ant-select-tree-title {
  font-family: 'Content', sans-serif !important;
  color: #1079c9;
  font-weight: bold;
}

.ant-select-tree-node-content-wrapper {
  font-family: 'Content', sans-serif !important;
}
.ant-drawer
  .ant-drawer-title{
    font-size: 18px;
    color: #1079c9;
    padding: 0px;
   
    font-family: 'Content', sans-serif;
  }
.span-styels{
  margin: 5px;
  
  color: #1079c9;
  font-size: medium;
    font-family: 'Content', sans-serif;
  }
  .ant-popconfirm
  .ant-popconfirm-description {
    font-family: "Content";
    
     
  }
 
  .custom-row-class {
    /* Add your styles here */
    background-color: #3d0b0b; /* Example background color */
    font-weight: bold; /* Example font weight */
}

.table-container {
   
  overflow-y: auto; /* Enable vertical scrolling */
}


.rounded-table{
  border-radius: 10px;
  overflow: hidden;
  font-family: 'Content', sans-serif;
}
 
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  font-family: 'Content', sans-serif;
  font-size: 14px;
  background-color: #f9f5f5;
   border-width: 1px;
 
}

.navbar-left,
.navbar-right {
  display: flex;
  align-items: center;
}

.buttonstylekhmer {
  font-size: 14px;
}

 
 .disabled-row {
   pointer-events: none;
   background-color: #d9d9d9;
   /* Prevents interaction */
   opacity: 0.5;
   /* Makes the row appear visually disabled */
 }

.image-profile {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  top: 150px;
  left: 42%;
  transform: translateX(-50%);
  background: linear-gradient(#0c7abd, #e2d9d8, #d7dde1);
  /* Gradient for the border */
  padding: 2px;
  /* Space for the border */
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); */
  /* Subtle shadow effect */
}

.image-profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: white;
  /* Background for the inner circle */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  /* Subtle shadow effect */
}
.post-list {
  display: flex;
  flex-wrap: wrap;
  /* Allows items to wrap onto the next line */
  gap: 5px;
  /* Space between items */
  justify-content: flex-start;
  /* Adjusts spacing between rows */
}

.post-list>* {
  flex: 1 1 300px;
  /* Items have a base width of 300px but can grow/shrink */
  max-width: calc(33.333% - 20px);
  /* Optional: restrict to 3 items per row with gap */
}

    /* @media print {
      .print-page-mission {
        page-break-after: always;
      }
  
      .print-page-mission:last-child {
         
        page-break-after: auto;
      }
    } */
         @media print {
           .print-page-mission {
             /* margin: 50px 50px 0; */
           }
    
           .print-page-mission:first-child {
             page-break-after: always;
           }
            .single-staff-page {
                          page-break-inside: avoid;
                        }
         }
 


         .styled-table-mission {
           width: 100%;
           border-collapse: collapse;
           margin: 20px 0;
           font-size: 16px;
           text-align: center;
         }
    
         .styled-table-mission th,
         .styled-table-mission td {
           border: 1px solid gray;
           padding: 10px;
         }
    
         .styled-table-mission th {
           background-color: #f2f2f2;
         }
    
         .khmer-text {
          font-size: 14px;
            text-align: center;
            /* Adjust font size as needed */
            font-family: 'Content', serif;
         }
     .khmer-text-footer {
                       font-size: 7px;
                       /* Adjust font size as needed */
                       font-family: 'Content', serif;
                       color: #0667b1;
                     }
                           
                                                        .ant-segmented-item-selected {
                                                          color: #5dade2 !important;
                                                          /* Selected text color */
                                                    
                                                          font-weight: bold;
                                                          /* Selected background */
                                                        }


  .ant-form-item-label {
    font-family: 'Content', sans-serif;
    
    font-weight: bold;
  }

  .ant-menu-label {
    font-family: 'Content', sans-serif;
  font-size:small;font-weight: bold;
  }
        .khmer-font-content {
          font-family: 'Content', sans-serif;
        
          font-size: small;
        }
               
        
                .CircularProgressbar-text {
                  font-weight: bold;
                }
 .custom-head-table {
  background-color: #4CAF50;
  /* Green background */
  color: white;
  /* White text */
}