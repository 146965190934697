

  /* fonts.css */

@font-face {
  font-family: 'Khmer OS Battambang';
  src: url('../styles/fonts/KhmerOSBattambang-Regular.ttf') format('truetype'); /* Adjust the path to your font file */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', 'Content';
}
.select {
  color: rgb(6, 102, 146) !important;
  font-family: 'Content', sans-serif !important;
}
 
.ant-select-selection-item {
  color: rgb(6, 102, 146) !important;
  font-family: 'Content', sans-serif !important;
}