/* StyleAttendance.css */

/* Customize active pagination link color */
.ant-pagination-item-active a,
.ant-pagination-item-active a:hover {
    color: azure;
   
    /* Set the color for active page link */
}

/* Optional: Customize previous and next button color */
.ant-pagination-prev,
.ant-pagination-next {
    color: #1890ff;
    /* Set color for previous and next button */
}