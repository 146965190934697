/* sm: (Small devices, typically 640px and up)
 md: (Medium devices, typically 768px and up)
  lg: (Large devices, typically 1024px and up)
xl: (Extra-large devices, typically 1280px and up) */

.Staff-list {
        font-family: 'Content', sans-serif;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 10px;
}
 
/* For large screens (desktop) */
@media screen and (min-width: 1280px) {
    .Staff-list {
        grid-template-columns: repeat(3, auto);
            /* 3 columns of auto-sized items */
            justify-content: flex-start;
            /* Center content horizontally */
            align-items: start;
            /* Align content vertically */
    }
        
}
 @media screen and (max-width: 1024px) {
     .Staff-list {
         grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
     }
    
 }
 


 @media screen and (max-width: 868px) {
     .Staff-list {
         grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
     }
          
          
 }
@media screen and (max-width: 768px) {
    .Staff-list {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
         
       
}

@media screen and (max-width: 480px) {
    .Staff-list {
      
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}
 
/* For larger screens */
@media screen and (max-width: 768px) {
    .box-header {
        flex-direction: row;
        /* Horizontal layout for wider screens */
        justify-content: space-between;
        align-items: center;
    }
}

.custom-pagination .ant-pagination-item {
    border-color: rgb(105, 105, 110) !important;
    border-radius: 50%;
   
}
.custom-pagination .ant-pagination-item a {
    color: rgb(93, 90, 90) !important;
}

.custom-pagination .ant-pagination-item-active {

    border-color: rgb(48, 49, 48) !important;
    color: white !important;

    background-color: #239b56 !important;
}

.custom-pagination .ant-pagination-item-active a {
    color: white !important;

}

.Model-Setting-Grid-User {
    display: grid;
    gap: 16px;
    
    /* Adjust the spacing between grid items as needed */
}
.Model-Setting-Grid-User div p {
    font-family: 'Content';
}

@media screen and (max-width: 767px) {

    /* Mobile screens */
    .Model-Setting-Grid-User {
        grid-template-columns: 1fr;
        /* 1 column */
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

    /* Tablet screens */
    .Model-Setting-Grid-User {
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns */
    }
}

@media screen and (min-width: 1025px) {

    /* Laptop and larger screens */
    .Model-Setting-Grid-User {
        grid-template-columns: repeat(3, 1fr);
        /* 3 columns */
    }
}