.sarchPenel{
    display: flex;
    justify-content:space-between;
     
    font-family: 'Content', sans-serif;
    align-items: center;
    padding-bottom: 5px;
}
 .customFont{
    font-family: 'Content', sans-serif;
 }
 .customDiv{
    padding-top: 10px;
 }