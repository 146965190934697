.Model-Setting-Grid {
    display: grid;
    gap: 16px;
    /* Space between grid items */
}

/* Mobile screens */
@media screen and (max-width: 767px) {
    .Model-Setting-Grid {
        grid-template-columns: 1fr;
        /* Single column */
    }
}

/* Tablet screens */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .Model-Setting-Grid {
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
    }
}

/* Computer screens */
@media screen and (min-width: 1025px) {
    .Model-Setting-Grid {
        grid-template-columns: repeat(4, 1fr);
        /* Four columns */
    }
}

 