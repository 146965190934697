  
  .myfontkhmer  {
    color: rgb(10, 94, 178);
    font-family: 'Content', sans-serif;;
    font-size: 24px;
   
    /* Add any other styles you want for your custom class */
  }
  .myfontkhmer-table  {
    color: rgb(6, 14, 22);
    font-family: 'Content', sans-serif;;
    font-size: 16px;
   
    /* Add any other styles you want for your custom class */
  }

  .myfontkhmer-button  {
    color: rgb(6, 14, 22);
    font-family: 'Content', sans-serif;;
    font-size: 14px;
   
    /* Add any other styles you want for your custom class */
  }