 /* Default styles for all screen sizes */
.custom-modal {
    font-family: 'Content', sans-serif;
    color: #b30a0a;
    justify-items: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
 
  /* Media query for screens with a maximum width of 600 pixels */
  @media only screen and (max-width: 600px) {
    .custom-modal div {
      font-family: 'Content', sans-serif;
      color: #280898;
      width: 400px;
      display: flex;
      flex-direction: column;
   
    
    }
    
    .custom-modal div > * {
        margin: 10;
        padding: 10;
        /* Apply styles to all direct children of .custom-modal div */
        width: 350px;
      }
  }
  
  /* Media query for screens with a minimum width of 601 pixels and a maximum width of 900 pixels */
  @media only screen and (min-width: 601px) and (max-width: 900px) {
    .custom-modal {
    
      font-family: 'Content', sans-serif;
    }
  }
  
  /* Media query for screens with a minimum width of 901 pixels */
  @media only screen and (min-width: 901px) {
    .custom-modal {
      font-family: 'Content', sans-serif;
    }
  }
  