/* sm: (Small devices, typically 640px and up)
 md: (Medium devices, typically 768px and up)
  lg: (Large devices, typically 1024px and up)
xl: (Extra-large devices, typically 1280px and up) */

.Department-Card {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
}
.Leader-Card {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
}
/* For large screens (desktop) */
@media screen and (min-width: 1024px) {
    .Department-Card {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
                 .Leader-Card {
                     justify-content: center;
                 
                     grid-template-columns: repeat(auto-fit, max(300px));
                 }
}

@media screen and (max-width: 1024px) {
    .Department-Card {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
                 .Leader-Card {
                        justify-content: center;
                       
                     grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                 }
}

@media screen and (max-width: 868px) {
    .Department-Card {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
                 .Leader-Card {
                     grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                 }
}

@media screen and (max-width: 768px) {
    .Department-Card {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
                 .Leader-Card {
                     grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                 }
}

@media screen and (max-width: 480px) {
    .Department-Card {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
           .Leader-Card {
               grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
           }
}
  